/**
 * Controllered TextField
 * 
 * @Author: Focci
 * @Date: 2023-04-25 14:56:50
 * @Last Modified by: Focci
 * @Last Modified time: 2023-04-25 14:56:50
 */

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

export default function ControllerFiled({
  Input,
  inputProps = null,
  form = null,
  name = '',
  rules,
  defaultValue = '',
  className = '',
  ...props
}) {
  const { control, formState: { errors } } = form
  const err = errors[name] && errors[name].message

  return (
    <div className={clsx('relative w-full max-w-full', className)}>
      <Controller
        {...props}
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules || {}}
        render={({ field }) => (
          <Input
            {...inputProps}
            {...field}
            {...(rules ? { error: Boolean(err) } : {})}
            // error={Boolean(err)} // 这里还是要传Boolean类型，否则会报错
          />
        )}
      />
      {err && (
        <div className="ml-1 text-red-500 text-f.6">
          {err}
        </div>
      )}
    </div>
  )
}

ControllerFiled.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  color: PropTypes.string,
  rules: PropTypes.object,
  textProps: PropTypes.object,
}

// /**
//  * Controller Filed
//  * 
//  * @Author: Focci
//  * @Date: 2023-06-25 11:45:16
//  * @Last Modified by: Focci
//  * @Last Modified time: 2023-06-25 11:45:16
//  */

// import PropTypes from 'prop-types'
// import { Controller } from 'react-hook-form'

// export default function ControllerFiled({
//   // Input = null,
//   inputProps = null,
//   form = null,
//   name = '',
//   rules = {},
//   defaultValue = '',
//   ...props
// }) {
//   const { control, formState: { errors } } = form
//   const err = errors[name] && errors[name].message

//   return (
//     <div className="relative w-full max-w-full">
//       <Controller 
//         {...props}
//         defaultValue={defaultValue}
//         control={control}
//         rules={rules}
//         render={({ field }) => (
//           <input
//             {...inputProps} 
//             {...field}
//             // error={!!errors[name]}
//           />
//         )}
//       />
//       {err && (
//         <div className="ml-1">
//           {err}
//         </div>
//       )}
//     </div>
//   )
// }

// ControllerFiled.propTypes = {
//   // Input: PropTypes.node.isRequired,
//   inputProps: PropTypes.object,
//   form: PropTypes.object.isRequired,
//   name: PropTypes.string.isRequired,
//   rules: PropTypes.object,
//   defaultValue: PropTypes.string,
// }
