import { domain } from '@lib/config'

/**
 * 获取cookie option
 * option: {Object}
 * 
 * @param {Boolean} millisecond expires的值是否为毫秒
 * @param {Number} expires 过期时间(单位：天)
 */
export function getCookieOption(option) {
  const { millisecond = false, expires = 730 } = option || {}
  const tmp = { domain }

  if(typeof expires === 'number') {
    tmp.expires = millisecond 
      ? Date.now() + (expires * 24 * 60 * 60 * 1000)
      : expires
  }
  
  return tmp
}
