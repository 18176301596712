/**
 * 绑定邮箱(没有验证邮件用户的)
 * 
 * @Author: Focci
 * @Date: 2024-09-03 15:01:33
 * @Last Modified by: Focci
 * @Last Modified time: 2024-09-03 15:01:33
 */

'use client'

// import PropTypes from 'prop-types'
import useLogin from '@hook/useLogin'
import MailIcon from '@mui/icons-material/Mail'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import SVGLogoIcon from '@img/logo-icon.svg'
import useMessage from '@hook/useMessage'
import { getCookieOption } from '@lib/get-cookie-option'
import { HG_NEXTTIME_BIND_EMAIL } from '@lib/config'
import { useTranslations } from 'next-intl'
import { fetchProxy, fetchPut } from '@lib/fetch/client'
import { getDayjs, getTimeDiff } from '@lib/datetime'
import { cookieGet, cookieSet } from '@lib/utils'
import { useForm } from 'react-hook-form'
import { Button, ButtonBase, Dialog } from '@comp/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import VerifyEmail from '../../base/verifyEmail'

export default function BindUnverifiedEmail() {
  const form = useForm()
  const { message } = useMessage()
  const { isLogin, user, setUser } = useLogin()
  const tc = useTranslations('common')
  const tl = useTranslations('login')

  const [nextTime, setNextTime] = useState()
  const [param, setParam] = useState({ code: '', email: user?.email })
  const [saving, setSaving] = useState(false)
  
  form.watch((data) => setParam(data))
  useEffect(() => {
    user && setNextTime(cookieGet(HG_NEXTTIME_BIND_EMAIL))
  }, [user])
  
  /**
   * 是否显示对话框
   */
  const showDialog = useMemo(
    () => (
      isLogin 
        && (user?.isEmailVerified === false || user?.is_email_verified === false) 
        && (!nextTime || getTimeDiff(nextTime, 'day') > 7)
    ), 
    [isLogin, nextTime, user]
  )
  
  /**
   * 是否禁用 “确定” 按钮
   */
  const disabledButton = useMemo(
    () => {
      const { email, code } = param || {}
      return !(email && String(code).trim().length === 6)
    },
    [param]
  )

  /**
   * 下次再说
   */
  const handleCancel = useCallback(() => {
    const now = getDayjs().unix()
    const opt = getCookieOption()
    
    setNextTime(now)
    cookieSet(HG_NEXTTIME_BIND_EMAIL, now, opt)
    // 
  }, [setNextTime])

  /**
   * 绑定
   */
  const handleConfirm = useCallback(() => {
    const { email } = param || {}
    
    fetchProxy(
      fetchPut('user/email', param),
      setSaving
    ).then(() => {
      message(tc('saveSuccessfully'))
      handleCancel()
      setUser({
        ...user,
        email,
        is_email_verified: true
      })
    }).catch((err) => {
      message(err.message)
    })
  }, [param, message, tc, setUser, user, handleCancel])
  
  if(!showDialog) {
    return null
  }
  
  return (
    <Dialog open={true}>
      <div className="p-8 w-[24rem] max-w-full">
        <div className="text-center text-f1.2 font-bold">
          {tl('bindEmailTip')}
        </div>
        <div className="mt-6 flex items-center justify-center gap-6">
          <div className="w-16 h-16 rounded-full bg-[#b4e2ff] flex items-center justify-center">
            <MailIcon sx={{ fontSize: '2rem' }} className="fill-white" />
          </div>
          <div>
            <InsertLinkIcon sx={{ fontSize: '2rem' }} className="fill-gray-300" />
          </div>
          <div className="text-primary">
            <SVGLogoIcon />
          </div>
        </div>
        <div className="text-center text-meta text-f.7 my-6">
          <div>{tl('registerThanks')}</div>
          {tl('bindEmailPrompt')}
        </div>
        <div className="flex flex-col gap-2">
          <VerifyEmail form={form} />
        </div>
        <Button
          type="button"
          className="h-pix-50 mt-4 w-full"
          onClick={handleConfirm}
          disabled={disabledButton}
        >
          {saving ? tl('loading') : tl('confirm')}
        </Button>
        <div className="mt-4 text-meta text-center">
          <ButtonBase onClick={handleCancel}>
            {tl('remindLater')}
          </ButtonBase>
        </div>
      </div>
    </Dialog>
  )
}

// BindUnverifiedEmail.propTypes = {
//   className: PropTypes.string,
// }
