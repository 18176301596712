'use client'

import { isWindow } from '@lib/utils'
import useStorage from '@hook/useStorage'
import useLogin from '@hook/useLogin'
import { createContext, useMemo, useState, useEffect, useCallback } from 'react'
import { fetchGet } from '@lib/fetch/client'
import { isArray, isObject, forEach, map } from 'lodash-es'
import { HOUGARDEN_FIRE_EVENT_LOCATIONS_MAP, HOUGARDEN_LOCATIONS_DATA } from '@lib/config'
import { getUnix } from '@lib/datetime'
import { useLocale } from 'next-intl'

export const AppContent = createContext()

function updateCheck(storage) {
  const now = getUnix()
  const needRefetch = (
    !storage // 不存在缓存
    // 没有下次更新时间
    || !storage.last_update_time_zone
    // 如果更新时间小于当前时间(3天更新一次)
    || (storage.last_update_time_zone + 60 * 60 * 24 * 3) < now
  )
  
  return { now, needRefetch }
}

export function AppProvider({
  children
}) {
  const locale = useLocale()
  const { getItem, setItem } = useStorage(`${HOUGARDEN_LOCATIONS_DATA}_${locale}`)
  const { getItem: getItemFE, setItem: setItemFE } = useStorage(HOUGARDEN_FIRE_EVENT_LOCATIONS_MAP)

  const w = isWindow ? document.documentElement.clientWidth : 20000

  // client width
  const [clientWidth, setClientWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(w < 768)
  const [isTablet, setIsTablet] = useState(w < 1024)
  const { isLogin } = useLogin()

  const handleResize = useCallback(() => {
    const width = document.documentElement.clientWidth
    setClientWidth(width)
    setIsMobile(width < 768)
    setIsTablet(width < 1024)
  }, [])

  useEffect(() => {
    if(!isWindow) return () => {}

    handleResize()
    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize, false)
    }
  }, [handleResize])

  // 
  // ============================================================
  // 如果用户已经登录，获取已收藏的房源、添加的开放日、已订阅的房源等数据
  const [favorites, setFavorites] = useState([]) // 收藏的房源
  const [openhomes, setOpenhomes] = useState([]) // 开放日
  const [subcribes, setSubcribes] = useState([]) // 已订阅的房源(已认领)
  const [isInitial, setIsInitial] = useState(false) // 是否正在请求数据

  const refetchUserActivity = useCallback(async () => {
    setIsInitial(true)
    const { fav, open_auction: openhome, track } = await fetchGet('user-states')
    
    isArray(fav) && setFavorites(fav)
    isArray(track) && setSubcribes(track)

    // 用户添加的开放日数据
    if(isObject(openhome)) {
      const tmp = {}
      forEach(openhome, (val, key) => {
        tmp[key] = map(val, (v) => `${v.type}_${v.start}`)
      })
      setOpenhomes(tmp) // { [houseId]: [type_start, ...] }
    }
    
    setIsInitial(false)
  }, [])

  // 
  // ============================================================
  // 判断用户登录状态，来初始化或者清除数据
  useEffect(() => {
    let timer
    if(!isLogin) {
      setFavorites([])
      setOpenhomes([])
      setSubcribes([])
    } else {
      timer = setTimeout(() => refetchUserActivity(), 100)
    }
    return () => timer && clearTimeout(timer)
  }, [refetchUserActivity, isLogin])

  // 
  // ============================================================
  // 地区、城区映射名称
  const storage = getItem()
  const [locations, setLocations] = useState(isObject(storage) ? storage : {})

  useEffect(() => {
    const tmp = {}
    const { now, needRefetch } = updateCheck(storage)

    const getItems = (target, items, prefix = 'region') => {
      map(isArray(items) ? items : [], (item) => {
        const key = `${prefix}_${item[`${prefix}_id`]}`
        target[key] = { zh: item.name }
        
        if(item.districts) {
          getItems(target, item.districts, 'district')
        }
        if(item.suburbs) {
          getItems(target, item.suburbs, 'suburb')
        }
      })
    }

    if(needRefetch) {
      fetchGet('location-names').then((res) => {
        getItems(tmp, res)
      }).finally(() => {
        tmp.last_update_time_zone = now
        setItem(tmp)
        setLocations(tmp)
      })
    }
  }, [getItem, setItem, storage])

  // 
  // ============================================================
  // 事件参数值映射(region, district, suburb)
  const storageFE = getItemFE()
  const [evtcfg, setEvtcfg] = useState(isObject(storageFE) ? storageFE : {})

  useEffect(() => {
    const { now, needRefetch } = updateCheck(storageFE)
    
    if(needRefetch) {
      fetchGet('fire-event-locations-map').then((res) => {
        if(res) {
          res.last_update_time_zone = now
          setEvtcfg(res)
          setItemFE(res)
        }
      })
    }
  }, [setItemFE, storageFE])

  // 
  // ============================================================
  // 通过 useApp() 返回的数据
  const config = useMemo(() => ({
    clientWidth,
    isMobile,
    isTablet,
    favorites,
    openhomes,
    subcribes,
    isInitial,
    locations,
    evtcfg,
    setFavorites,
    setOpenhomes,
    setSubcribes,
    refetchUserActivity,
  }), [
    clientWidth, isMobile, isTablet, isInitial, evtcfg, locations,
    favorites, openhomes, subcribes, refetchUserActivity, 
  ])

  return (
    <AppContent.Provider value={config}>
      {children}
    </AppContent.Provider>
  )
}
