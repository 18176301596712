import Image from 'next/image'
import { Button } from '@comp/material'
import { get, map } from 'lodash-es'
import { useLocale } from 'next-intl'
import { DialogScoff } from './DialogScoff'
import { campaignEvent, getUrl } from './_'

export default function QuestionMessage(props) {
  const { data, onClose } = props
  const locale = useLocale()

  function clickHandle(item, key) {
    const url = getUrl(item, locale)
    url && window.open(url, '_self')

    campaignEvent(data.pushId, key)
    onClose()
  }

  return (
    <DialogScoff {...props}>
      <Image
        src={data.image || ''}
        width={460}
        height={345}
        alt={data.header}
      />
      <div className="text-f1.2 font-semibold leading-6 mt-pix-20 break-words line-clamp-2">{data.header}</div>
      <div className="text-f.8 text-meta leading-2 mt-pix-10 line-clamp-2">{data.body}</div>
      {
        map(get(data, 'action'), (item, key) => item && (
          <Button
            key={key}
            variant={key !== 'secondary' ? 'contained' : 'outlined'}
            sx={{ width: '100%', mt: '20px' }} 
            onClick={() => clickHandle(item, key)}
          >
            {get(item, 'label')}
          </Button>
        ))
      }
    </DialogScoff>
  )
}
