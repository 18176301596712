import { fetchPost } from './client'

/**
 * 账号密码登录
 */
export async function login(params) {
  return fetchPost('login', params)
}

/**
 * 获取图形验证码
 */
export async function getCaptcha(identifier) {
  return fetchPost(`captcha/${identifier}`)
}

/**
 * 验证图形验证码
 */
export async function verifyCaptcha(identifier, captcha) {
  return fetchPost('captcha-check', { identifier, captcha })
}

/**
 * 发送短信验证码
 */
export async function sendSmsCode(countryCode, mobileNumber) {
  return fetchPost('login/sms/pre', { countryCode, mobileNumber })
}

/**
 * 短信登录
 */
export async function loginSms(countryCode, mobileNumber, code) {
  return fetchPost('login/sms', { countryCode, mobileNumber, code })
}

/**
 * 发送邮箱验证码
 */
export async function sendEmailCode(email, exist = 1, type = 'general_code') {
  return fetchPost('email', { email, exist, type })
}

/**
 * 邮箱验证码注册
 */
export async function signupEmail(email, code, password, gender = 3) {
  return fetchPost('users-email', { email, code, password, gender })
}

/**
 * 短信验证码注册
 */
export async function signupSms(countryCode, mobileNumber, code, password, gender = 3) {
  return fetchPost('users', { countryCode, mobileNumber, code, password, gender })
}

/**
 * 邮箱找回密码
 */
export async function resetPasswordByEmail(email, code, newPassword, skip = 0) {
  return fetchPost('user/password/email', { email, code, newPassword, skip })
}

/**
 * 短信找回密码
 */
export async function resetPasswordByMobile(countryCode, mobileNumber, code, newPassword) {
  return fetchPost('user/password/mobile', { countryCode, mobileNumber, code, newPassword })
}

/**
 * google登录
 */
export async function googleLogin(code) {
  return fetchPost('thirdLoginV2', { code, sourceCode: 'google' })
}

/**
 * wechat登录（扫码登录）
 */
export async function wechatLogin(code) {
  return fetchPost('thirdLoginV2', { code, sourceCode: 'wechat' })
}

/**
 * wechat登录（授权登录）
 */
export async function wechatAuth(code) {
  return fetchPost('wechatLoginV2', { code, sourceCode: 'wechat' })
}

/**
 * 微信绑定手机号
 */
export async function wechatBindMobile(countryCode, mobileNumber, code, token, sourceCode = 'wechat') {
  return fetchPost('thirdLogin-bind', { countryCode, mobileNumber, code, token, sourceCode })
}

/**
 * 微信绑定邮箱地址
 */
export async function wechatBindEmail(email, code, token, sourceCode = 'wechat') {
  return fetchPost('thirdLogin-bind', { email, code, token, sourceCode })
}
