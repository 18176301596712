'use client'

import useLogin from '@hook/useLogin'
import SVGLogo from '@img/logo.svg'
import { Button } from '@comp/material'
import clsx from 'clsx'
import { isFunction, map } from 'lodash-es'
import { useTranslations } from 'next-intl'
import { useCallback, useMemo } from 'react'

export default function LoginLayout({
  children
}) {
  const ts = useTranslations('login')
  const {
    signup,
    forgot,
    forgotByMobile,
    forgotByEmail,
    bindMobile,
    bindEmail,
    bindPassword,
    bindPasswordSuccess,
    openSigninDialog,
    openSignupDialog
  } = useLogin()

  const toggleHandle = useCallback(() => {
    signup ? openSigninDialog() : openSignupDialog()
  }, [signup, openSigninDialog, openSignupDialog])

  const title = useMemo(() => {
    if (signup) {
      return ts('registerTitle')
    }

    if (forgot) {
      if (forgotByMobile) {
        return ts('forgotByMobileTitle')
      }

      if (forgotByEmail) {
        return ts('forgotByEmailTitle')
      }

      return ts('forgotTitle')
    }

    if (bindMobile) {
      return ts('bindMobileTitle')
    }

    if (bindEmail) {
      return ts('bindEmailTitle')
    }

    if (bindPassword) {
      if (bindPasswordSuccess) {
        return ''
      }
      return ts('bindPasswordTitle')
    }

    return ts('title')
  }, [
    ts,
    signup,
    forgot,
    forgotByMobile,
    forgotByEmail,
    bindMobile,
    bindEmail,
    bindPassword,
    bindPasswordSuccess
  ])

  return (
    <div className="w-pix-480 max-w-full mx-auto bg-white p-pix-20 relative md:p-pix-40">
      <SVGLogo className="h-pix-60 w-pix-152 text-primary mx-auto" />
      {
        title && (
          <div className="text-center mt-pix-30 text-2xl font-medium">{title}</div>
        )
      }
      <div className="mt-pix-20">
        {children}
      </div>
      {
        !forgot && !bindMobile && !bindPassword && (
          <div className="mt-pix-20 flex items-center justify-between">
            <span className="text-f.7 text-c3b">
              {ts(signup ? 'haveAccount' : 'noAccount')}
            </span>
            <Button variant="outlined" size="small" onClick={toggleHandle}>{ts(signup ? 'login' : 'register')}</Button>
          </div>
        )
      }
    </div>
  )
}

export function Tabs({
  data,
  value,
  onChange
}) {
  const clickHandle = useCallback((item) => () => {
    isFunction(onChange) && onChange(item.value)
  }, [onChange])
  return (
    <div className="flex justify-center">
      {
        map(data, (item) => (
          <div key={item.value} className="before:w-[1px] before:h-pix-18 before:bg-ce6 first:before:hidden flex items-center">
            <Button
              variant="text"
              onClick={clickHandle(item)}
              className={clsx('text-f.8 font-semibold py-[4px] leading-[16px] block', item.value === value ? 'text-primary' : 'text-info')}
            >
              {item.label}
            </Button>
          </div>
        ))
      }
    </div>
  )
}

export function Split({
  children
}) {
  return (
    <div className={
      clsx(
        children
          ? 'grid grid-cols-[1fr,auto,1fr] items-center gap-x-pix-10 py-pix-20 before:h-[1px] before:bg-ce6 after:h-[1px] after:bg-ce6'
          : 'py-pix-20 before:bg-ce6 before:h-[1px] before:block'
      )
    }
    >
      {children}
    </div>
  )
}

export function Row({
  children,
  className
}) {
  return (
    <div className={clsx('flex gap-x-pix-10', className)}>
      {children}
    </div>
  )
}
