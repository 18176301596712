/**
 * DialogScoff
 * 
 * @Author: Focci
 * @Date: 2024-10-31 16:18:37
 * @Last Modified by: Focci
 * @Last Modified time: 2024-10-31 16:18:37
 */

// import PropTypes from 'prop-types'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, IconButton } from '@comp/material'
import { useBoolean } from 'react-use'

export function DialogScoff({ data, onClose, children }) {
  const [open, setOpen] = useBoolean(Boolean(data))
  function closeHandle() {
    setOpen(false)
    onClose()
  }
  
  return (
    <Dialog open={open}>
      <div className="w-pix-500 max-w-full p-pix-20 relative">
        <IconButton 
          sx={{ position: 'absolute', right: 0, top: 0, p: '4px' }} 
          onClick={closeHandle}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </div>
    </Dialog>
  )
}
