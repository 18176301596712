import { HG_NEXTTIME_BIND_EMAIL, REFRESH_TOKEN_NAME, TOKEN_NAME, domain } from '@lib/config'
import { cookieGet, cookieSet } from '@lib/utils'
import login, { loginSelector } from '@store/login'
import { useCallback, useEffect } from 'react'
import { useBoolean } from 'react-use'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { setEnquiryCookie } from './useEnquiryInfo'

export default function useLogin() {
  const setState = useSetRecoilState(login)
  const state = useRecoilValue(loginSelector)
  const { user } = state
  const [isLogin, setIsLogin] = useBoolean(!!user && !!cookieGet(TOKEN_NAME))

  useEffect(() => {
    setIsLogin(!!user && !!cookieGet(TOKEN_NAME))
  }, [setIsLogin, user])

  // 显示登录对话框
  const openSigninDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      signin: true,
      signup: false,
      forgot: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: false
    }))
  }, [setState])

  // 显示注册对话框
  const openSignupDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: true,
      forgot: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: false
    }))
  }, [setState])

  // 显示忘记密码对话框
  const openForgotDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: true,
      forgotByEmail: false,
      forgotByMobile: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: false
    }))
  }, [setState])

  // 显示忘记密码通过邮箱找回对话框
  const openForgotByEmailDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: true,
      forgotByEmail: true,
      forgotByMobile: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: false
    }))
  }, [setState])

  // 显示忘记密码通过手机找回对话框
  const openForgotByMobileDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: true,
      forgotByMobile: true,
      forgotByEmail: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: false
    }))
  }, [setState])

  // 显示绑定手机对话框
  const openBindMobileDialog = useCallback((token) => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: false,
      forgotByMobile: false,
      forgotByEmail: false,
      bindMobile: true,
      bindEmail: false,
      bindPassword: false,
      bindToken: token
    }))
  }, [setState])

  // 显示绑定邮箱对话框
  const openBindEmailDialog = useCallback((token) => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: false,
      forgotByMobile: false,
      forgotByEmail: false,
      bindMobile: false,
      bindEmail: true,
      bindPassword: false,
      bindToken: token
    }))
  }, [setState])

  // 显示设置密码对话框
  const openBindPasswordDialog = useCallback((email) => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: false,
      forgotByMobile: false,
      forgotByEmail: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: true,
      bindUserName: email
    }))
  }, [setState])

  // 显示设置密码成功对话框
  const openBindPasswordSuccessDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      bindPasswordSuccess: true
    }))
  }, [setState])

  // 关闭登录/注册对话框
  const closeDialog = useCallback(() => {
    setState((d) => ({
      ...d,
      signin: false,
      signup: false,
      forgot: false,
      forgotByMobile: false,
      forgotByEmail: false,
      bindMobile: false,
      bindEmail: false,
      bindPassword: false,
      bindPasswordSuccess: false,
      bindToken: null,
      bindUserName: null
    }))
  }, [setState])

  // 修改用户信息
  const setUser = useCallback((u, validate = true) => {
    const email = u?.email || ''
    const phone = u?.mobile_number || u?.mobileNumber || ''
    const isEmailVerified = u?.is_email_verified || u?.isEmailVerified
    const isMobileVerified = u?.is_email_verified || u?.isMobileVerified

    const uinfo = {
      ...u,
      email: validate ? (isEmailVerified ? email : '') : email,
      mobile_number: validate ? (isMobileVerified ? phone : '') : phone,
    }

    setState((d) => ({ ...d, user: u ? uinfo : null }))

    // 登录成功, 设置enquiry信息
    u && setEnquiryCookie({
      name: uinfo.nickname,
      email: uinfo.email,
      phoneNumber: uinfo.mobile_number
    })
  }, [setState])

  // 退出登录
  const logout = useCallback(() => {
    setUser(null)
    cookieSet(TOKEN_NAME, '', { expires: 0, domain })
    cookieSet(REFRESH_TOKEN_NAME, '', { expires: 0, domain })
    cookieSet(HG_NEXTTIME_BIND_EMAIL, '', { expires: 0, domain })
  }, [setUser])

  return {
    ...state,
    isLogin,
    openSigninDialog,
    openSignupDialog,
    openForgotDialog,
    openForgotByEmailDialog,
    openForgotByMobileDialog,
    openBindMobileDialog,
    openBindEmailDialog,
    openBindPasswordDialog,
    openBindPasswordSuccessDialog,
    closeDialog,
    setUser,
    logout
  }
}
