import { generateUrl } from '@lib/utils'
import { getRouteUrl } from './lib'

/**
 * 编辑个人信息
 */
export function editProfile(locale) {
  return getRouteUrl('/u/profile', null, locale)
}

/**
 * 我发布的租房
 */
export function myPublish(locale) {
  return getRouteUrl('/u/my-publish', null, locale)
}

/**
 * 发布租房
 */
export function publish(locale) {
  return getRouteUrl('/u/publish', null, locale)
}

/**
 * 编辑租房
 */
export function editPublish(id, locale) {
  return getRouteUrl(`/u/publish/${id}`, null, locale)
}

/**
 * 浏览记录
 */
export function browsingHistory(slug = 'listings', locale = undefined) {
  return getRouteUrl(`/u/browsing-history/${slug}`, null, locale)
}

/**
 * 我的订阅
 */
export function mySubscription(locale) {
  return getRouteUrl('/u/subscription', null, locale)
}

/**
 * 订阅房源列表
 */
export function subscriptionListing(id, locale) {
  return getRouteUrl(`/u/subscription/${id}`, null, locale)
}

/**
 * 我的行程
 */
export function myAppointments(locale) {
  return getRouteUrl('/u/appointments', null, locale)
}

/**
 * 我的认领
 */
export function myClaim(locale) {
  return getRouteUrl('/u/claim', null, locale)
}

/**
 * 咨询
 */
export function enquiry(locale) {
  return getRouteUrl('/u/enquiry', null, locale)
}

/**
 * 消息
 */
export function notifications(slug = 'system', locale = undefined) {
  return getRouteUrl(`/u/notifications/${slug}`, null, locale)
}

/**
 * 聊天页面
 */
export function chat(account, nick, locale) {
  return generateUrl(getRouteUrl('/u/notifications/chat', null, locale), { account, nick: encodeURIComponent(nick) })
}

/**
 * 收藏的房源
 */
export function favourites(locale) {
  return getRouteUrl('/u/favourites', null, locale)
}

/**
 * 收藏的房源详情
 */
export function favourite(id, locale) {
  return getRouteUrl(`/u/favourites/${id}`, null, locale)
}

/**
 * 贷款计算器
 */
export function loanCalculator(locale) {
  return getRouteUrl('/u/loan-calculator', null, locale)
}

/**
 * 推送设置
 */
export function pushSetting(locale) {
  return getRouteUrl('/u/email', null, locale)
}

/**
 * 国家/语言
 */
export function language(locale) {
  return getRouteUrl('/u/language', null, locale)
}
