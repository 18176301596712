/**
 * Styled Input
 * 
 * @Author: Focci
 * @Date: 2023-04-17 10:47:58
 * @Last Modified by: Focci
 * @Last Modified time: 2023-04-17 10:47:58
 */

'use client'

// import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { InputBase } from '@comp/material'

function StyledInput({ 
  variant = 'filled',
  error = false,
  sx = null,
  ...props 
}, ref) {
  const sxConfig = (theme) => {
    let tmp = { 
      fontSize: '.7rem',
      width: '100%',
      padding: '.3rem .8rem',
    }

    if(variant === 'outline') {
      tmp = {
        ...tmp,
        backgroundColor: error ? '#ffeeee' : '#fff',
        border: 'solid 1px',
        borderColor: error ? '#ffa7a7' : '#9b9b9b',
        borderRadius: '.2rem',
        '&.Mui-focused': {
          borderColor: theme.palette.primary.main
        } 
      }
    } else if(variant === 'filled') {
      tmp = {
        ...tmp,
        backgroundColor: error ? '#ffeeee' : '#f5f5f7',
      }
    }
    
    return { ...tmp, ...sx }
  }

  return (
    <InputBase
      sx={sxConfig}
      {...props}
      ref={ref}
    />
  )
}

export default forwardRef(StyledInput)
