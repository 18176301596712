/**
 * 这里面不能引入 lodash 库，因为里面包含 new Function 之类的操作
 * middleware.js中 runtime不支持
 */
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import { defaultLocale } from '@i18n/config'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/en-nz'
import { timeZone } from './config'
import { isAU } from './utils'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export function getDayjs(...args) {
  const djs = dayjs(...args)
  return isAU ? djs : djs.tz(timeZone)
}

/**
 * 返回一个13位的时间cuo
 */
export function getTimestamp(timestamp) {
  let t = timestamp
  if (/^[\d+]{6,10}$/.test(t)) {
    t = parseInt(t, 10) * 1000
  } else if (typeof t === 'string') {
    t = getDayjs(t).valueOf()
  }

  return t
}

/**
 * 返回一个10位的时间cuo
 */
export function getUnix(datetime) {
  return getDayjs(getTimestamp(datetime || new Date())).unix()
}

/**
 * 获取给定时间cuo与当前时间的差（分）
 */
export function getTimeDiff(timestamp, unit = 'minute') {
  const t = getTimestamp(timestamp)
  const currentTime = getDayjs()
  return currentTime.diff(t, unit)
}

/**
 * 返回 几分钟前， 几小时前, 几天前...
 * @param {String|Number} timestamp 时间cuo、时间字符串、日期对象
 * @return {String}
 */
export function timeAgo(timestamp, format = 'YYYY-MM-DD HH:mm:ss') {
  const t = getTimestamp(timestamp)
  const diff = getTimeDiff(timestamp)
  let res = ''

  if (diff < 60) {
    res = `${diff} mins ago`
  } else if (diff < 60 * 24) {
    res = `${Math.floor(diff / 60)} hours ago`
  } else if (diff < 60 * 24 * 2) {
    res = `${Math.floor(diff / (60 * 24))} days ago`
  } else {
    res = getDayjs(t).format(format)
  }

  return res
}

/**
 * 返回 几分钟前， 几小时前... 
 * @param {String|Number|Object} timestamp 时间cuo、时间字符串、日期对象
 * @returns 
 */
export function timeAgoHours(timestamp) {
  const t = getTimestamp(timestamp)
  const diff = getTimeDiff(timestamp)
  let res = ''

  if (diff < 1) {
    res = 'just now'
  } else if (diff < 60) {
    res = `${diff} mins ago`
  } else if (diff < 60 * 24) {
    res = getDayjs(t).format('HH:mm:ss')
  } else {
    res = getDayjs(t).format('MM-DD HH:mm:ss')
  }

  return res
}

/**
 * 格式化时间（天，时，分，秒），并返回一个对象
 * 
 * @param {Number} seconds 秒
 * @returns 
 */
export function formatSeconds(seconds) {
  if (seconds < 0) {
    return {
      d: '00', h: '00', m: '00', s: '00'
    }
  }

  let sec = seconds
  const day = Math.floor(sec / (24 * 60 * 60))
  sec %= 24 * 60 * 60

  const hours = Math.floor(sec / (60 * 60))
  sec %= 60 * 60

  const minutes = Math.floor(sec / 60)
  sec %= 60

  return {
    d: day.toString().padStart(2, '0'),
    h: hours.toString().padStart(2, '0'),
    m: minutes.toString().padStart(2, '0'),
    s: sec.toString().padStart(2, '0')
  }
}

export function formatDate(date, format = 'YYYY-MM-DD HH:mm:ss', lang = '') {
  const t = getTimestamp(date)
  const locale = lang || global.hgLang || defaultLocale
  return getDayjs(t).locale(locale === 'zh' ? 'zh-cn' : 'en-nz').format(format)
}

export function formatLang(date, str, lang) {
  let url = str
  const matchParams = url.match(/\[([\w$]+)\]/g)
  
  if (matchParams) {
    matchParams.forEach((item) => {
      const prop = item.slice(1, item.length - 1)
      url = url.replace(`[${prop}]`, formatDate(date, prop, lang))
    })
  }

  return url
}

/**
 * 返回到当前的相对时间
 */
export function fromNow(date, locale = false) {
  const t = getTimestamp(date)
  const now = dayjs()
  const targetDateTime = getDayjs(t)
  if (now.diff(targetDateTime, 'year') >= 1) {
    return targetDateTime.format('YYYY-MM-DD HH:mm:ss')
  }
  return targetDateTime.locale(locale === 'zh' ? 'zh-cn' : 'en-nz').fromNow()
}

/**
 * 返回到当前的相对时间
 */
export function timeToHHmm(time) {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`
}

/**
 * 判断时间是否在指定的月份内
 */
export function isWithinMonth(timestamp, month = 12) {
  const targetDate = getDayjs(getTimestamp(timestamp))
  const sixMonthsAgo = getDayjs().subtract(month, 'month')
  
  return targetDate.isAfter(sixMonthsAgo)
}
