import { stringifyToUrl } from '@lib/parse'
import { newsSearchRules } from '@lib/parse/config'
import { getRouteUrl } from './lib'

/**
 * 直播列表页
 */
export function list(category = 'all', page = 1, locale = undefined) {
  return getRouteUrl('/live/{slug}', { slug: stringifyToUrl({ category, page }, newsSearchRules) }, locale)
}
