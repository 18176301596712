'use client'

import AppInitialization from '@comp/app'
import Cookies from 'js-cookie'
import { Dialog } from '@comp/material'
import { createRoot } from 'react-dom/client'
import { NextIntlClientProvider } from 'next-intl'
import { defaultLocale, localCookieName } from '@i18n/config'
import { getMessages } from '@i18n/get-lang'

/**
 * 挂载一个组件到指定容器
 * 
 * @param {Any} component 要挂载的组件
 * @param {Element} target 挂载位置
 * @returns 
 */
async function mount(component, target = document.body) {
  const locale = Cookies.get(localCookieName) || defaultLocale
  const container = document.createElement('div')

  const messages = await getMessages()
  
  target.appendChild(container)
  let root = createRoot(container)

  function destroy(e) {
    if(!e || e.currentTarget === e.target) {
      root.unmount()
      if (container?.parentNode) {
        container.parentNode.removeChild(container)
      }
      root = null
    }
  }
  
  root.render(
    <NextIntlClientProvider locale={locale} messages={messages}>
      <AppInitialization>
        {component}
      </AppInitialization>
    </NextIntlClientProvider>
  )

  return {
    destroy
  }
}

async function mountDialog(component) {
  const res = await mount(
    <Dialog open={true} onClose={() => res.destroy()}>
      {component}
    </Dialog>
  )

  return res
}

export default function usePortal() {
  return {
    mount,
    mountDialog,
  }
}
