/**
 * PromoteMessage
 * 
 * @Author: Focci
 * @Date: 2024-10-31 16:00:35
 * @Last Modified by: Focci
 * @Last Modified time: 2024-10-31 16:00:35
 */

// import PropTypes from 'prop-types'
import Image from 'next/image'
import Link from 'next/link'
import { useLocale } from 'next-intl'
import { useCallback, useMemo } from 'react'
import { DialogScoff } from './DialogScoff'
import { campaignEvent, getUrl } from './_'

export default function PromoteMessage(props) {
  const { data, onClose } = props
  const locale = useLocale()
  
  const src = useMemo(() => (
    getUrl(data?.action?.primary, locale)
  ), [data?.action?.primary, locale])

  const handleClick = useCallback(() => {
    campaignEvent(data.pushId, 'primary')
    onClose()
  }, [data.pushId, onClose])

  return (
    <DialogScoff {...props}>
      <Link 
        href={src} 
        onClick={handleClick}
      >
        <Image
          src={data.image || ''}
          width={460}
          height={640}
          alt={data.header}
        />
      </Link>
    </DialogScoff>
  )
}
