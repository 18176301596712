import { UUID_PROP } from '@lib/config'
import Event from '@lib/event'
import { cookieGet } from '@lib/utils'
import { Base64 } from 'js-base64'
import { get, map } from 'lodash-es'

class SysMessageLib extends Event {
  constructor(wsToken) {
    super()

    this.ws = null
    this.wsToken = wsToken

    // 是否已经连接
    this.isOpen = false
    // 是否已加入房间
    this.isJoinRoom = false
    // 当前加入的房间id
    this.roomId = null
  }

  init() {
    const uuid = cookieGet(UUID_PROP)
    const wsUrl = `${process.env.NEXT_PUBLIC_WEBSOCKET_URL}${this.wsToken || `web_${uuid}`}`

    if (!uuid && !this.wsToken) {
      // eslint-disable-next-line no-console
      console.error('Error: User-uuid is not found .')
      return this
    }

    const WebSocket = window.WebSocket || window.MozWebSocket
    if (!WebSocket) {
      // eslint-disable-next-line no-console
      console.error('Error: WebSocket is not supported .')
      return this
    }

    const ws = new WebSocket(wsUrl)

    ws.onopen = () => {
      this.isOpen = true
      this.emit('open')
    }

    ws.onmessage = (evt) => {
      const data = JSON.parse(evt.data)
      this.emit('message', data)
    }

    this.ws = ws
    return this
  }

  // 加入房间
  joinRoom(roomId) {
    if (!this.ws) {
      // eslint-disable-next-line no-console
      console.error('Error: WebSocket is not found .')
      return false
    }

    if (!roomId) {
      // eslint-disable-next-line no-console
      console.error('Error: roomId is not found .')
      return false
    }

    if (this.isJoinRoom) return true

    const sendHandle = () => {
      this.ws.send(
        JSON.stringify({
          action: 'join_chat',
          chat_room_id: roomId
        })
      )
      this.isJoinRoom = true
      this.roomId = roomId
    }

    if (this.isOpen) {
      sendHandle()
    } else {
      this.on('open', () => {
        sendHandle()
      })
    }

    return true
  }

  // 离开房间
  leaveRoom(roomId) {
    if (!this.ws) {
      // eslint-disable-next-line no-console
      console.error('Error: WebSocket is not found .')
      return false
    }

    if (!this.isJoinRoom) return true

    this.ws.send(
      JSON.stringify({
        action: 'quit_chat',
        chat_room_id: this.roomId || roomId
      })
    )
    this.isJoinRoom = false

    return true
  }

  // 监听连接
  onOpen(callback) {
    this.on('open', callback)
  }

  // 监听消息
  onMessage(callback) {
    this.on('message', callback)
  }

  // 发送消息
  sendRoom(roomId, text) {
    if (!this.ws) {
      // eslint-disable-next-line no-console
      console.error('Error: WebSocket is not found .')
      return false
    }

    if (!text) {
      // eslint-disable-next-line no-console
      console.error('Error: text is not found .')
      return false
    }

    this.ws.send(
      JSON.stringify({
        action: 'send_chat',
        chat_room_id: this.roomId || roomId,
        message: text || ''
      })
    )

    return true
  }

  // 销毁连接
  destroy() {
    if (this.isOpen) {
      this.ws.close()
    }
    // this.ws = null
    // this.wsToken = null
    this.isOpen = false
    this.isJoinRoom = false
  }
}

export function initSysMessage(wsToken) {
  return new SysMessageLib(wsToken).init()
}

export const MsgType = {
  message: 'chat_room_message',
  system: 'chat_room_system_message',
  inAppMsg: 'in_app_message',
  event: 'event'
}

function eventParser(item) {
  const key = get(item, 'data.key')
  switch (key) {
    // 在线人数更新
    case 'online_number':
      return {
        onlineNumber: get(item, 'data.value', 0)
      }
    // 有新的流推送
    case 'live_push_started':
      try {
        const startedAnchor = JSON.parse(get(item, 'data.value'))
        return { startedAnchor }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('live_push_started: 解析失败', get(item, 'data.value'))
      }
      return {}
    // 有流结束推送
    case 'live_push_stopped':
      try {
        const stoppedAnchor = JSON.parse(get(item, 'data.value'))
        return { stoppedAnchor }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('live_push_stopped: 解析失败', get(item, 'data.value'))
      }
      return {}
    // 公开课开启
    case 'live_started':
      return { liveStarted: true }
    // 公开课结束
    case 'live_finished':
      try {
        const liveFinished = JSON.parse(get(item, 'data.value'))
        return { liveFinished }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('live_finished: 解析失败', get(item, 'data.value'))
      }
      return {}
    // 主播开启混流通知, 混流更改
    case 'live_cdn_started':
    case 'live_cdn_url_change':
      try {
        const m3u8Url = get(item, 'data.value')
        return { m3u8Url }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('live_cdn_started/live_cdn_url_change: 解析失败', get(item, 'data.value'))
      }
      return {}
    case 'change_relates':
      try {
        const liveRelates = JSON.parse(Base64.decode(get(item, 'data.value')))
        return { liveRelates }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('change_relates: 解析失败', get(item, 'data.value'))
      }
      return {}
    case 'live_announcement':
      return { liveAnnouncement: Base64.decode(get(item, 'data.value')) }
    default:
      return {}
  }
}

export function analyzeMessage(msgs) {
  const chat = []
  let onlineNumber = 0
  let startedAnchor
  let stoppedAnchor
  let liveStarted
  let liveFinished
  let m3u8Url
  let liveRelates
  let liveAnnouncement
  map(msgs, (item) => {
    switch (item.type) {
      case MsgType.message:
      case MsgType.system:
        chat.push(item)
        break
      case MsgType.event:
        // eslint-disable-next-line no-case-declarations
        const d = eventParser(item)
        onlineNumber = d.onlineNumber
        startedAnchor = d.startedAnchor
        stoppedAnchor = d.stoppedAnchor
        liveStarted = d.liveStarted
        liveFinished = d.liveFinished
        m3u8Url = d.m3u8Url
        liveRelates = d.liveRelates
        liveAnnouncement = d.liveAnnouncement
        break
      default:
        // eslint-disable-next-line no-console
        console.log('未知消息类型：', item.type, item)
        break
    }
  })
  return {
    chat,
    onlineNumber,
    startedAnchor,
    stoppedAnchor,
    liveStarted,
    liveFinished,
    m3u8Url,
    liveRelates,
    liveAnnouncement
  }
}
