import { baseUrls, countryAlias } from '@lib/config'
import { stringifyToUrl } from '@lib/parse'
import { newsSearchRules } from '@lib/parse/config'
import { getRouteUrl } from './lib'

/**
 * 新闻列表页
 */
export function list(category = 'all', page = 1, locale = undefined) {
  return getRouteUrl('/news/{slug}', { slug: stringifyToUrl({ category, page }, newsSearchRules) }, locale)
}

/**
 * 新闻详情
 */
export function article(data, locale, isAu = false) {
  return getRouteUrl('/news/{slug}', { slug: data?.slug || '' }, locale, isAu ? baseUrls[countryAlias.au] : baseUrls[countryAlias.nz])
}

/**
 * 新闻tag页面
 */
export function tag(id, locale) {
  return getRouteUrl('/news-tag/{id}', { id }, locale)
}

/**
 * 新闻搜索页面
 */
export function search(keywords, page = 1, locale = undefined) {
  return getRouteUrl(`/news-search/{keywords}${page > 1 ? `?page=${page}` : ''}`, { keywords }, locale)
}
