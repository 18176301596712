import { stringifyToUrl } from '@lib/parse'
import { newsSearchRules } from '@lib/parse/config'
import { getRouteUrl } from './lib'

/**
 * topic首页
 */
export function homepage(category = 'all', locale = undefined) {
  return getRouteUrl('/topics/{slug}', { slug: stringifyToUrl({ category }, newsSearchRules) }, locale)
}

/**
 * topic详情
 */
export function detail(data, locale) {
  return getRouteUrl('/topic/{slug}', { slug: data.slug }, locale)
}
