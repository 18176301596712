import { defaultLocale, locales } from '@i18n/config'
import { isWindow } from '@lib/utils'

export function getClientLocale() {
  let res = ''
  if(isWindow) {
    const lang = window.location.pathname.split('/')[1]
    locales.indexOf(lang) > -1 && (res = lang)
  }

  return res || defaultLocale
}

export async function getMessages() {
  return (await import(`./modules/${getClientLocale()}.json`)).default
}
