import { SnackbarProvider } from 'notistack'

const messageConfig = {
  maxSnack: 3,
  hideIconVariant: true,
  autoHideDuration: 4000,
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'top'
  },
  style: {
    borderRadius: '9999px',
    justifyContent: 'center',
    opacity: 0.95
  }
}

export function MessageProvider({ children }) {
  return (
    <SnackbarProvider {...messageConfig}>
      {children}
    </SnackbarProvider>
  )
}
