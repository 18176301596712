import { stringifyToUrl } from '@lib/parse'
import { newsSearchRules } from '@lib/parse/config'
import { getRouteUrl } from './lib'

/**
 * 知道列表页
 */
export function list(category = 'all', page = 1, locale = undefined) {
  return getRouteUrl('/knowledge/{slug}', { slug: stringifyToUrl({ category, page }, newsSearchRules) }, locale)
}

/**
 * 知道详情页
 */
export function detail(slug, locale) {
  return getRouteUrl(`/knowledge/topic/${slug}`, null, locale)
}
