/**
 * localStorage
 * 
 * @Author: Focci
 * @Date: 2023-08-25 11:06:58
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-25 11:06:58
 */

import { isWindow } from '@lib/utils'
import { useCallback, useMemo } from 'react'

/**
 * @param {Boolean} session 是否使用 sessionStorage 来存储
 */
export default function useStorage(key, session = false) {
  const storage = useMemo(
    () => (isWindow ? (session ? sessionStorage : localStorage) : {}), 
    [session]
  )
  
  const setItem = useCallback((value) => {
    if(!isWindow) return
    if (typeof value === 'object') {
      storage.setItem(key, JSON.stringify(value))
    } else {
      storage.setItem(key, value)
    }
  }, [key, storage])
  
  const getItem = useCallback(() => {
    if(!isWindow) return null
    const res = storage.getItem(key)

    try {
      return JSON.parse(res)
    } catch (error) {
      return res
    }
  }, [key, storage])
  
  const removeItem = useCallback(() => {
    storage.removeItem(key)
  }, [key, storage])

  return {
    setItem,
    getItem,
    removeItem,
  }
}
