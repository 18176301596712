export default class Event {
  constructor() {
    this.events = []
  }

  on(type, callback) {
    this.events.push({
      type,
      callback
    })
  }

  emit(type, data) {
    this.events.forEach((event) => {
      if (event.type === type) {
        event.callback(data)
      }
    })
  }

  off(type) {
    this.events = this.events.filter((event) => event.type !== type)
  }

  once(type, callback) {
    const fn = (...args) => {
      callback(...args)
      this.off(type)
    }
    this.on(type, fn)
  }
}
