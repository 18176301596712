import { TOKEN_NAME, REFRESH_TOKEN_NAME, HG_USER_KEY, UUID_PROP } from '@lib/config'
import { delay, isFunction } from 'lodash-es'
import { getClientLocale } from '@i18n/get-lang'
import { getCookieOption } from '@lib/get-cookie-option'
import { cookieGet, cookieSet } from '@lib/utils'
import { fetchData, getRealLang } from './lib'

function clearUser() {
  try{
    const opt = getCookieOption({ expires: -1 })

    cookieSet(HG_USER_KEY, '', opt)
    cookieSet(TOKEN_NAME, '', opt)
    cookieSet(REFRESH_TOKEN_NAME, '', opt)

    delay(() => window.location.reload(), 100)
  } catch(error) {
    // 
  }
}

export function clientFetchData(method, apiUrl, query, options, token, refreshToken, uuid) {
  const clientToken = !token ? cookieGet(TOKEN_NAME) : token
  const clientUUID = !uuid ? cookieGet(UUID_PROP) : uuid
  const locale = getClientLocale()
  
  const clientOptions = {
    headers: {
      'Accept-Language': getRealLang(locale),
      countryiso: process.env.NEXT_PUBLIC_COUNTRY
    },
    ...options,
  }
  
  return fetchData(method, apiUrl, query, clientOptions, clientToken, clientUUID)
    .catch(async (err) => {
      const refreshtoken = refreshToken || cookieGet(REFRESH_TOKEN_NAME)
      
      // 如果报403的话，刷新token
      if(err.status === 403 && refreshtoken && apiUrl !== 'token-refresh') {
        if(refreshtoken) {
          try{
            const result = await fetchData('post', 'token-refresh', { refreshToken: refreshtoken })

            if(result?.token) {
              const option = getCookieOption()

              cookieSet(TOKEN_NAME, result.token, option)
              cookieSet(REFRESH_TOKEN_NAME, result.refresh_token, option)

              return fetchData(method, apiUrl, query, clientOptions, result.token, clientUUID)
            // eslint-disable-next-line no-else-return
            } else {
              clearUser()
            }
          } catch(error) {
            clearUser()
          }
        } else {
          clearUser()
        }
      }

      throw err
    })
}

export function fetchGet(...args) {
  return clientFetchData('get', ...args)
}

export function fetchPost(...args) {
  return clientFetchData('post', ...args)
}

export function fetchPut(...args) {
  return clientFetchData('put', ...args)
}

export function fetchDelete(...args) {
  return clientFetchData('delete', ...args)
}

/**
 * 处理请求(捕获错误 & loading处理)
 * 
 * @param {Promise} promise 
 * @param {ref} loading 必须是响应的
 * @returns 
 */
export function fetchProxy(promise, setLoading) {
  isFunction(setLoading) && setLoading(true)

  return promise
    .finally(() => isFunction(setLoading) && setLoading(false))
}
