import { eventTypes } from '@lib/config'
import { getRouteUrl } from './lib'

/**
 * event详情
 */
export function detail(data, locale) {
  if (!data) return '/'
  const { eventType } = data
  return getRouteUrl(`/${eventType === eventTypes.openCourse ? 'opencourse' : 'meetup/events'}/{id}`, { id: data.eventId || data.id }, locale)
}

/**
 * 公开课test页面
 */
export function openCourseTest(data, locale) {
  if (!data) return '/'

  return getRouteUrl('/opencourse/{id}/test', { id: data.id }, locale)
}

/**
 * 公开课room页面
 */
export function openCourseRoom(data, locale) {
  if (!data) return '/'

  return getRouteUrl('/opencourse/{id}/room', { id: data.id }, locale)
}
