/**
 * useMessage
 * 
 * 在使用前，需要在将 components/message/index => MessageProvider
 * 包裹在外面
 * 
 * @Author: Focci
 * @Date: 2023-08-13 21:57:15
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-13 21:57:15
 */

import { enqueueSnackbar, closeSnackbar } from 'notistack'
import { isObject, isString } from 'lodash-es'

export default function useMessage() {
  /**
   * https://notistack.com/api-reference#mutual-props
   * 
   * type: default | error | success | warning | info
   */
  function message(text, type = 'default', options = null) {
    const content = isString(text)
      ? text
      : isObject(text) && text.response
        ? text?.response?.data?.err || text?.toString()
        : text?.toString()

    if (!content) {
      return undefined
    }
    
    return enqueueSnackbar(content, { 
      variant: type,
      ...options
    })
  }

  return {
    message,
    close: closeSnackbar
  }
}
