import { clone, isArray, forEach, map } from 'lodash-es'
import { houseSearchType } from '@lib/config'
import * as routeHouse from '@lib/route/house'
import * as routeLives from '@lib/route/live'
import * as routeAgent from '@lib/route/agent'
import * as routeTopic from '@lib/route/topic'
import * as routeProfile from '@lib/route/profile'
import * as routeKnowledge from '@lib/route/knowledge'
import * as routeNews from '@lib/route/news'
import * as routeEvent from '@lib/route/event'
import * as routeCommon from '@lib/route/common'

/**
 * 解析广告url
 */
export function parseAd(data, locale, isMobile) {
  const tmp = clone(data || {})
  
  tmp.src = isMobile && tmp.addition_cover_oss 
    ? (tmp.addition_cover_oss || tmp.cover_oss) 
    : tmp.cover_oss

  switch(tmp.type) {
    case 'house':
      tmp.href = routeHouse.propertyDetail(data?.house?.slug || data?.slug || '', locale)
      break
    case 'news':
    case 'knowledge-news':
      tmp.href = routeNews.article(data?.news || data, locale)
      break
    case 'residential-index':
      tmp.href = routeHouse.search(houseSearchType.residential, '', locale)
      break
    case 'rental-index':
      tmp.href = routeHouse.search(houseSearchType.rental, '', locale)
      break
    case 'estimate-index':
      tmp.href = routeHouse.search(houseSearchType.estimate, '', locale)
      break
    case 'homepage':
      tmp.href = routeCommon.home(locale)
      break
    case 'commercial-sale':
      tmp.href = routeHouse.search(houseSearchType.commercialForSale, '', locale)
      break
    case 'commercial-lease':
      tmp.href = routeHouse.search(houseSearchType.commercialForLease, '', locale)
      break
    case 'knowledge-index':
      tmp.href = routeKnowledge.list('all', 1, locale)
      break
    case 'knowledge-topic':
      tmp.href = routeKnowledge.detail(tmp.slug, locale)
      break
    case 'topic':
      tmp.href = routeTopic.detail(tmp, locale)
      break
    case 'find-agent':
      tmp.href = routeAgent.findAnAgent(locale)
      break
    case 'agent':
      tmp.href = routeAgent.detail(tmp?.slug || tmp.id, locale)
      break
    case 'publish-rental':
      tmp.href = routeProfile.publish(locale)
      break
    case 'event':
      tmp.href = routeEvent.detail({
        eventType: tmp.event?.event_type,
        id: tmp.event?.event_id
      }, locale)
      break
    case 'event-list':
      tmp.href = routeLives.list('all', 1, locale)
      break
    default:
      tmp.href = tmp.url || tmp.id || tmp.value
  }
  return tmp
}

/**
 * 初始化广告数据
 */
export function initialAd(data) {
  const tmp = {}

  if(isArray(data)) {
    forEach(data, (item) => {
      tmp[item.locationId] = isArray(item.ads) ? item.ads : []
    })
  }

  return tmp
}

export function handlePushTrack(data, pushTrack, type) {
  const param = {
    id: data?.id,
    adId: data?.ad_id,
    locationId: data?.location_ids?.[0],
    animation: data?.house?.animation,
  }

  // 房源
  if(data?.house) {
    param.agentId = isArray(data.house.agents) ? map(data.house.agents, 'id').join(',') : ''
    param.agencyOfficeId = data.house.office_id
    param.specialType = data.house.animation ? 'name_card' : ''
  }

  pushTrack(type, param, { once: true })
}
