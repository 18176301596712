/**
 * Material UI default theme
 */
import CheckboxCheckedIcon from '@img/checkbox-checked-icon.svg'
import CheckboxIcon from '@img/checkbox-outline-icon.svg'

export const defaultConfig = {
  palette: {
    mode: 'light',
    divider: '#e7e7e7',
    primary: {
      main: '#20a4f8',
      dark: '#008be4',
      contrastText: '#ffffff',
      light: '#f6fdff',
    },
    secondary: {
      main: '#00bc94',
      dark: '#00a884',
      contrastText: '#ffffff',
      light: '#f1edf6',
    },
    error: {
      main: '#FF3838',
      dark: '#BO3E3E',
      contrastText: '#ffffff',
      light: '#ffdad8',
    },
    warning: {
      main: '#ff7e26',
      dark: '#dda625',
      contrastText: '#ffffff',
      light: '#fffbf0',
    },
    success: {
      main: '#33ba3d',
      dark: '#087E19',
      contrastText: '#ffffff',
      light: '#d5ffd3',
    },
    info: {
      main: '#3c3c3c',
      dark: '#8F8E8E',
      contrastText: '#ffffff',
      light: '#eeeeee',
    },
    gray: {
      main: '#d0d4e1',
      dark: '#ccc',
      contrastText: '#ffffff',
      light: '#ffffff',
    },
    light: {
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
      light: '#fff',
    },
    lightButton: {
      main: '#e6e6e6',
      dark: '#e6e6e6',
      contrastText: '#3B4450',
      light: '#fff',
    },
    darkGray: {
      main: '#6e7386',
      dark: '#6e7386',
      contrastText: '#fff',
      light: '#fff',
    },
    coffee: {
      main: '#855628',
      dark: '#855628',
      contrastText: '#fff',
      light: '#fff',
    },
    deepBlue: {
      main: '#2351a6',
      dark: '#2351a6',
      contrastText: '#fff',
      light: '#fff',
    },
    text: {
      primary: '#3c3c3c',
    },
    red: {
      main: '#FF3838',
      light: '#FF3838',
      dark: '#FF3838',
      contrastText: '#FFFFFF'
    },
  },
  typography: {
    fontSize: 14,
  },
  spacing: 10,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          fontSize: '1em',
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--hougarden-font-family)',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'var(--hougarden-font-family)',
          fontWeight: 400
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckboxCheckedIcon />,
        icon: <CheckboxIcon />,
      },
      styleOverrides: {
        root: {
          fontSize: '1em',
          padding: '0px',
          color: '#ccc'
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '.7rem',
          lineHeight: '1rem',
        }
      }
    }
  }
}
