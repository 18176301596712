import InAppMessage from '@comp/inAppMessage'
import useLogin from '@hook/useLogin'
import { MsgType, initSysMessage } from '@lib/sys-msg'
import { each, get, isFunction, map } from 'lodash-es'
import { createContext, useContext, useEffect, useMemo, useRef } from 'react'
import { useBoolean, useList } from 'react-use'

const SysMsgContext = createContext()

export default function SysMsgProvider({ children }) {
  const { user, isLogin } = useLogin()
  const wsRef = useRef(null)
  const wsToken = useMemo(() => (isLogin && user ? user.ws_token : null), [isLogin, user])
  const [instaned, setInstaned] = useBoolean(false)

  // in app msg
  const [inAppMsg, { push: pushInAppMsg, removeAt: removeInAppMsg }] = useList([])
  // 消息事件
  const [messageEvent, { push: pushMessageEvent }] = useList([])
  // 初始化系统消息
  useEffect(() => {
    if (!isLogin && user) {
      return
    }

    if (wsRef.current) {
      wsRef.current.destroy()
      wsRef.current = null
      setInstaned(false)
    }

    const wslib = initSysMessage(wsToken)

    // 断线重连
    wslib.on('close', () => {
      // eslint-disable-next-line no-console
      console.error('WebSocket disconnected, reconnecting...')
      setTimeout(() => {
        wslib.init()
      }, 3000)
    })

    wslib.on('open', () => {
      setInstaned(true)
      wsRef.current = wslib
    })
  }, [user, isLogin, wsToken, wsRef, setInstaned])
  // 监听消息
  useEffect(() => {
    const wsRefCurrent = wsRef.current
    if (!wsRefCurrent || !instaned) return undefined

    function messageHandle(msgs) {
      each(messageEvent, (callback) => {
        isFunction(callback) && callback(msgs)
      })
      each(msgs, (item) => {
        switch (item.type) {
          // 处理系统级消息
          case MsgType.inAppMsg:
            pushInAppMsg({ ...get(item, 'data'), pushId: item?.push_id })
            break
          default:
            break
        }
      })
    }

    wsRefCurrent.on('message', messageHandle)
    return () => {
      wsRefCurrent.off('message', messageHandle)
    }
  }, [wsRef, messageEvent, pushInAppMsg, instaned])

  // state
  const state = useMemo(
    () => ({
      wsRef,
      instaned,
      pushMessageEvent
    }),
    [wsRef, instaned, pushMessageEvent]
  )

  return (
    <SysMsgContext.Provider value={state}>
      {
        map(inAppMsg, (item, idx) => (
          <InAppMessage data={item} onClose={() => removeInAppMsg(idx)} />
        ))
      }
      {children}
    </SysMsgContext.Provider>
  )
}

export function useSysMsg() {
  return useContext(SysMsgContext)
}
