'use client'

import { useCallback, useEffect } from 'react'
import { isFunction } from 'lodash-es'
import SlideMessage from './SlideMessage'
import QuestionMessage from './QuestionMessage'
import PromoteMessage from './PromoteMessage'
import { campaignEvent } from './_'

const comps = {
  PUSH_SLIDE: SlideMessage,
  PUSH_QUESTION: QuestionMessage,
  PUSH_PROMOTE: PromoteMessage,
}

export default function InAppMessage({
  data,
  onClose
}) {
  const Comp = comps[data.template_id] || comps.PUSH_QUESTION

  const handleClose = useCallback(() => {
    isFunction(onClose) && onClose()
    campaignEvent(data.pushId, 'close')
  }, [data, onClose])

  useEffect(() => {
    data && campaignEvent(data.pushId, 'show')
  }, [data])

  return data && (
    <Comp data={data} onClose={handleClose} />
  )
}
