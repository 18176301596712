import { HG_USER_KEY, REFRESH_TOKEN_NAME, TOKEN_NAME, UUID_PROP } from '@lib/config'
import { getCookieOption } from '@lib/get-cookie-option'
import { cookieGet, cookieSet } from '@lib/utils'
import { get as _get, pick } from 'lodash-es'
import { atom, selector } from 'recoil'

const login = atom({
  key: 'login',
  default: {
    signin: false,
    signup: false,
    forgot: false,
    forgotByEmail: false,
    forgotByMobile: false,
    bindMobile: false,
    bindEmail: false,
    bindPassword: false,
    bindPasswordSuccess: false,
    bindToken: null,
    bindUserName: null,
    user: null
  },
  effects: [
    ({ setSelf, onSet }) => {
      if (typeof localStorage === 'undefined') return

      onSet((newValue) => {
        const usr = _get(newValue, 'user')
        if (usr) {
          const user = pick(usr, [
            'id',
            'uuid',
            'avatar',
            'token',
            'nickname',
            'gender',
            'email',
            'mobile_number',
          ])

          // 从 google 登录过来的数据是驼峰的。。。。。。。
          user.refresh_token = usr.refresh_token || usr.refreshToken
          user.ws_token = usr.ws_token || usr.wsToken
          user.is_admin = usr.is_admin || usr.isAdmin

          // email是否验证
          user.isEmailVerified = !!(usr?.is_email_verified || usr?.isEmailVerified)

          const option = getCookieOption()
          user.refresh_token && cookieSet(REFRESH_TOKEN_NAME, user.refresh_token, option)
          user.token && cookieSet(TOKEN_NAME, user.token, option)
          user.uuid && cookieSet(UUID_PROP, user.uuid, option)

          cookieSet(HG_USER_KEY, JSON.stringify(user), option)
          // localStorage.setItem(HG_USER_KEY, JSON.stringify(newValue.user))
        } else {
          // localStorage.removeItem(HG_USER_KEY)
          cookieSet(HG_USER_KEY, '', getCookieOption({ expires: -1 }))
        }
      })
      // const user = JSON.parse(localStorage.getItem(HG_USER_KEY))
      const user = JSON.parse(cookieGet(HG_USER_KEY))
      setSelf((d) => ({ ...d, user }))
    }
  ]
})

export default login
export const loginSelector = selector({
  key: 'loginSelector',
  get: ({ get }) => {
    const { signin, signup, forgot, bindMobile, bindEmail, bindPassword, ...state } = get(login)
    return {
      ...state,
      signin,
      signup,
      forgot,
      bindMobile,
      bindEmail,
      bindPassword,
      open: signin || signup || forgot || bindMobile || bindEmail || bindPassword
    }
  }
})
