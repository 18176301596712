/**
 * https://s.oneroof.co.nz/image/25/23/25236d251a964b74f9f7841f0f8387de.jpg
 * 补全成
 * https://s.oneroof.co.nz/image/25/23/25236d251a964b74f9f7841f0f8387de.jpg?x-oss-process=image/quality,q_100
 * @param {string} src image src
 * @returns url
 */
export function paddingImageSrc(src) {
  if (src && src.includes('s.oneroof.co.nz/image')) {
    try {
      const url = new URL(src)
      !url.searchParams.has('x-oss-process') && url.searchParams.append('x-oss-process', 'image/quality,q_100')
      return url.toString()
    } catch (error) {
      return src
    }
  } else {
    return src
  }
}

/**
 * 图片修改尺寸
 * @param {string} src 图片地址
 * @param {number} width width
 * @param {number} height height
 * @param {string} m module
 * @returns src
 */
const ossFlag = 'x-oss-process'
export function imageSize(src, width, height, m = 'fill', quality = 80) {
  if (!src || /\.svg$/.test(src)) return src
  if (src.includes('googleapis')) return src

  if (src.includes(ossFlag)) {
    let res = [src]

    if(!src.includes('/resize,')) {
      if (width || height) res = [`${src}/resize`]
      if (width) res.push(`w_${width}`)
      if (height) res.push(`h_${height}`)
      if (width && height) res.push(`m_${m}`)
    }

    res = res.join(',')

    if(quality !== 80) {
      res = res.replace('quality,q_80', `quality,q_${quality}`)
    }

    return src.includes('/format,webp')
      ? res
      : `${res}/format,webp`
  }
  
  // const res = [src]
  // if (width) res.push(`w=${width}`)
  // if (height) res.push(`h=${height}`)
  // return res.join('&').replace(/&/, '?')

  return src
}

export default function imageLoader({ src, width }) {
  return imageSize(paddingImageSrc(src), width)
}
