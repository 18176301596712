'use client'

import ControllerFiled from '@comp/form/ControllerFiled'
import StyledInput from '@comp/form/StyledInput'
import { Button } from '@comp/material'
import useMessage from '@hook/useMessage'
import { sendEmailCode } from '@lib/fetch/login'
import { useTranslations } from 'next-intl'
import { useAsyncFn } from 'react-use'
import { Row } from './index'

export default function VerifyEmail({
  form,
  onDone,
  emailExist
}) {
  const ts = useTranslations('login')
  const { message } = useMessage()

  // 发送邮件验证码
  const [sendEmailCodeState, sendEmailCodeHandle] = useAsyncFn(async () => {
    if (!await form.trigger('email')) return
    const email = form.getValues('email')
    await sendEmailCode(email, emailExist)
      .then(() => {
        message(ts('sendEmailCodeSuccess'))
        onDone(true)
      })
      .catch((err) => form.setError('email', err))
  })

  return (
    <>
      <ControllerFiled
        name="email"
        inputProps={{ placeholder: ts('emailPlaceholder'), variant: 'outline' }}
        Input={StyledInput}
        form={form}
        rules={{ required: ts('emailPlaceholder'), pattern: { value: /\w+@\w+\.\w+/, message: ts('emailFormatError') } }}
      />
      <Row>
        <ControllerFiled
          name="code"
          inputProps={{ placeholder: ts('emailCodePlaceholder'), variant: 'outline', maxLenght: 6 }}
          Input={StyledInput}
          form={form}
          rules={{ required: ts('emailCodePlaceholder'), maxLength: { value: 6, message: ts('emailCodeMinLen') }, minLength: { value: 6, message: ts('emailCodeMinLen') } }}
        />
        <Button
          type="button"
          className="h-pix-42 flex-shrink-0"
          onClick={sendEmailCodeHandle}
          disabled={sendEmailCodeState.loading}
        >
          {sendEmailCodeState.loading ? ts('loading') : ts('getCode')}
        </Button>
      </Row>
    </>
  )
}
