import { parseAd } from '@comp/page/common/ad/lib'
import { fetchGet } from '@lib/fetch/client'

/**
 * 获取 campaign 链接
 */
export function getUrl(item, locale) {
  const d = parseAd(item, locale)
  return d?.href || ''
}

/**
 * fire event
 */
export function campaignEvent(pushId, action) {
  fetchGet('campaigns/stats', { pushId, action })
}
